@import "./mixins.scss";
.sidebar {
  background: url("../assets/sidebar-bg.svg") no-repeat 0 0;
  background-size: cover;
  position: relative;
  @include breakpoint(lg) {
    height: calc(100vh - 62px);
  }
  .sidebar-toggle {
    top: 0px;
    width: 100px;
    left: -98px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .sidebar-content {
    height: calc(100vh - 80px);
  }

  .close-filter-sidebar {
    margin: 0px auto;
    width: 100px;
    height: 100px;
    margin-bottom: -30px;
    &:before {
      content: " ";
      height: 100%;
      width: 100%;
      display: block;
      background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
      background-size: 100px 100px;
      transform: rotate(-90deg);
    }
  }

  &.expandable {
    transition: right 0.3s ease-in-out;
    position: absolute;
    top: 0px;
    right: -20rem;
    .sidebar-toggle {
      position: relative;
      top: 8px;
      left: -97px;
    }
  }

  &.expandable.expanded {
    right: -20rem;
  }

  &.expandable.expanded {
    right: 0rem;
  }
}
.options-select-container {
  border: 1px solid #2774ae;
  background: #fffdf5;
  border-radius: 3px;
  outline: none;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  &.showing-options {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .select-input {
      .select-sign:before {
        transform: rotate(180deg);
      }
    }
  }
  .select-input {
    border: none;
    .text-box {
      padding-left: 12px;
    }
    .select-sign {
      margin-right: 10px;
    }
    .select-sign:before {
      display: inline-block;
      color: #58bbd9;
      position: relative;
      transition: transform 0.3s ease-in-out;
      @include font-icon("\e900");
    }
  }
}

.select-options {
  background: #fffdf5;
  border-left: 1px solid #2774ae;
  border-bottom: 1px solid #2774ae;
  border-right: 1px solid #2774ae;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  li:hover {
    color: #58bbd9;
  }
}

@media (max-width: 768px) {
  .sidebar {
    min-width: 20rem;
    top: 0;
    position: absolute;
    &.expanded {
      height: calc(100vh - 3rem);
    }

    .sidebar-toggle {
      width: 100%;
      left: 0;
      top: 0;
      text-align: left;
      &:after {
        position: absolute;
        right: 0px;
        transition: transform 0.3s ease-in-out;
        @include font-icon("\e900");
      }
      &.expanded {
        &:after {
          transform: rotate(180deg);
        }
      }
      &.collapsed {
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .sidebar {
    height: calc(100vh - 3.75rem);
    top: 0;
    right: -15rem;
    position: absolute;
    transition: right 0.3s ease-in-out;
    &.expanded {
      right: 0;
    }
    .sidebar-toggle {
      top: 0.5rem;
    }
  }
}
