@import "./mixins.scss";
@import "./sidebar.scss";
@import "./recommendation.scss";
@import "./grid.scss";
@import "./fakeLoader.scss";

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: Lato, sans-serif;
}

.gradient-bg {
  background: transparent linear-gradient(180deg, #e9efee 0%, #bce1e7 100%);
}

#root {
  background: url("../assets/bg.svg") no-repeat bottom;
  background-size: contain;
}

aside {
  transition: width 0.3s, height 0.3s;
}

input {
  &:active,
  &:focus {
    outline: none;
  }
}

.tailwind-hidden {
  display: none;
}

.icon {
  color: red;
  border: none;
  background: transparent;
  font-size: 16px;
  padding: 0;

  &.search:before {
    @include font-icon("\e905");
  }

  &.menu:before {
    @include font-icon("\e90e");
  }

  &.export:before {
    @include font-icon("\e901");
  }
}

.expand-toggle {
  &:after {
    position: absolute;
    right: 0.5rem;
    transition: transform 0.3s ease-in-out;
    @include font-icon("\e900");
  }
  &.expanded {
    &:after {
      transform: rotate(180deg);
    }
  }
  &.collapsed {
    &:after {
      transform: rotate(0deg);
    }
  }
}

.expandable-card {
  border-radius: 0.5rem;
  transition: border-radius 0.3s ease-in-out;
  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ~ .expandable-card__content {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

button:focus {
  outline: none;
}

.group-tabs {
  .group-tab {
    font-size: 13px;
    transition: background-color 0.2s ease-in-out;
    background-color: #2494bd;
    border: 1px solid #2494bd;
    &.current,
    &:hover {
      background-color: #f1ae16;
      border: 1px solid #f1ae16;
    }
    &.current-blue {
      background-color: #2494bd;
      border: 1px solid #2494bd;
    }

    &.group-expanded {
      background-color: #fff;
      border: none;
      border-bottom: 1px solid #eaeaea;
      color: #000;
      &:active {
        background-color: #f1ae16;
      }
    }
  }

  .group-tab-toggle {
    border-radius: 20px;
    transition: all 0.1s ease-in-out;
    &:after {
      position: absolute;
      right: 10px;
      transition: transform 0.3s ease-in-out;
      @include font-icon("\e900");
    }
    &.expanded {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      &:after {
        transform: rotate(180deg);
      }
    }
    &.collapsed {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}

.ReactModal__Content.ReactModal__Content--after-open.ep-modal.auto {
  position: relative;
  width: auto;
  max-width: 1000px;
}

.page-content {
  max-height: calc(100vh - 60px);
  @include custom-scroll-bar();
  overflow-y: auto;
}

.custom-table {
  $border-color: #5c91d4;
  .custom-table__label {
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    flex: 0 0 170px;
    @include breakpoint(sm) {
      flex: 0 0 auto;
      border: none;
      font-weight: bold;
    }
  }

  &:last-child {
    .custom-table__label {
      border-bottom: none;
    }
    .custom-table__value:before {
      border-bottom: none;
    }
    @include breakpoint(sm) {
      .custom-table__value {
        border: none;
      }
    }
  }

  .custom-table__value {
    flex-grow: 1;
    position: relative;
    word-break: break-all;

    a {
      color: #5c77ff;
    }

    @include breakpoint(sm) {
      border-bottom: 1px solid $border-color;
    }
    &:before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 150px;
      bottom: 0px;
      left: 0px;
      border-bottom: 1px solid $border-color;
      @include breakpoint(sm) {
        display: none;
      }
    }
  }
}

.tooltip-container {
  max-width: 400px;
}
.expandable-card__content {
  .question-data:not(:last-child) {
    $border-color: #5c91d4;
    border-radius: 0;
    border-bottom: 1px solid $border-color;
  }
}

.quality-sign {
  font-family: "GRADE-quality";
  display: inline-block;
  vertical-align: top;
  margin-right: 1px;
  height: 16px;
  width: 15px;
  line-height: 15px;
  color: #f5a900;
}

.search-input {
  font-size: 14px;
  min-width: 300px;
  border-bottom: 1px solid #ffbd29;
  padding: 5px 10px;
  &.main-page {
    padding: 10px 20px;
    border: 1px solid #f2f6fa;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  }
}

.main-page-content {
  @include custom-scroll-bar();
}

.main-page-search-container {
  .btn-search {
    top: 4px;
    right: 5px;
  }
}
.glossary-links,
.social-link {
  display: block;
  padding: 10px;
  line-height: 30px;
  border-top: 1px solid #ffbd29;
  border-bottom: 1px solid #ffbd29;
  &:hover {
    opacity: 0.75;
  }
  &.twitter {
    text-align: center;
    .logo {
      display: inline-block;
      width: 30px;
      margin-top: -2px;
      margin-right: 15px;
      vertical-align: middle;
      height: 30px;
      background: url("../assets/twitter.svg") 50% 50% no-repeat;
      background-size: 30px 30px;
    }
  }
}

.btn-clear-search {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -40px;
  top: -3px;
  color: #fccb5c;
  outline: none;
  &:before {
    line-height: 36px;
    font-size: 36px;
    content: "\00d7";
  }
  &:hover {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
  }
  &:active {
    top: -2px;
    right: -41px;
  }
}

.btn-search {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: -3px;
  color: #fccb5c;
  outline: none;
  &:before {
    font-size: 20px;
    @include font-icon("\e905");
  }
  &:hover {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
  }
  &:active {
    top: -2px;
    right: -1px;
  }
}

.separator {
  height: 1px;
  background: #b2e0e5;
}

.work-in-progress {
  background: url("../assets/gear-bg.svg") no-repeat right center;
}

.modal-content {
  margin: -20px;
  padding: 20px;
}

.close-modal {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  &:before {
    content: "\00d7";
    font-size: 26px;
    display: block;
    width: 10px;
    height: 10px;
    color: #666;
  }
}

.btn {
  border: 1px solid #139b9f;
  color: #139b9f;
  border-radius: 6px;
  padding: 3px 5px;
  transition: background 0.3s;
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.75;
  }
}

.recommendations-to-compare {
  margin-top: 20px;
  max-height: calc(100vh - 250px);
  @include custom-scroll-bar();
  overflow: auto;
  li {
    .btn.highlight {
      color: #fff;
      background: #139b9f;
    }

    &:before {
      flex-shrink: 0;
      margin-right: 10px;
      content: " ";
      display: block;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
    }

    &.highlighted {
      background: #fff6e1;
      &:before {
        background: #ffe333;
      }
    }
  }
  li:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }
}

.spinner-img {
  animation: spinner-spin infinite 2s linear;
  pointer-events: none;
}

@keyframes spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  .top-nav-link {
    display: inline-block;
    padding: 3px 0px;
    line-height: 16px;
    border-bottom: 2px solid transparent;
    &.active,
    &:hover {
      font-weight: bold;
      border-bottom: 2px orange solid;
    }
  }
}

.see_more {
  font-size: 14px;
  color: #855e9c;
  position: relative;
  padding-right: 25px;
  &:after {
    position: absolute;
    right: 0px;
    top: 2px;
    content: " ";
    display: inline-block;
    width: 20px;
    height: 15px;
    background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
    background-size: 20px 15px;
  }
}

.hamburger-menu {
  position: relative;
  button.open-menu {
    position: absolute;
    right: 0px;
    z-index: 102;
  }
  .menu-container {
    height: calc(100vh);
    position: absolute;
    padding-top: 60px;
    width: 300px;
    right: -10px;
    top: -20px;
    border: 1px solid #ffbd29;
    background: #fff;
    z-index: 100;
    @include breakpoint(sm) {
      width: calc(100vw);
      right: -8px;
      top: -12px;
    }
  }

  .close-menu-arrow {
    width: 75px;
    height: 75px;
    margin-bottom: 20px;
    margin-left: 20px;
    &:before {
      content: " ";
      height: 100%;
      width: 100%;
      display: block;
      background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
      background-size: 75px 75px;
    }
    @include breakpoint(sm) {
      transform: rotate(270deg);
    }
  }
}

.main-page-header {
  .hamburger-menu {
    .menu-container {
      top: -13px;
    }
  }
}

.mobile-back-link {
  position: relative;
  width: 0;
  height: 0;
  margin-right: 20px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #f1ae16;
  &:after {
    position: absolute;
    left: 5px;
    top: -10px;
    content: " ";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
  }
}

.glossary-item {
  ul {
    li {
      margin-left: 15px;
      margin-bottom: 5px;
    }
  }
}

table.glossary {
  margin-top: 30px;
  thead {
    tr {
      border-bottom: 1px solid #009cca;
    }
    th:not(:last-child) {
      border-right: 1px solid #009cca;
    }
  }
  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid #009cca;
    }
  }

  td,
  th {
    vertical-align: top;
    padding: 10px 20px;
  }

  tbody {
    td:not(:last-child) {
      border-right: 1px solid #009cca;
    }
  }
}

@mixin groupGradient($startColor) {
  background: linear-gradient(
    90deg,
    $startColor 0%,
    rgba(255, 255, 255, 1) 2%,
    rgba(255, 255, 255, 1) 100%
  );
}

$allColor: #2494bd;
$screeningColor: #cd6332;
$preventionColor: #7cc4fd;
$treatmentColor: #92a5db;
$diagnosisColor: #5ebf8b;
$specialNeedsAndPopulationsColor: lighten(rgb(255, 200, 81), 5);
$tuberculosisAndComorbiditiesColor: lighten(#69123f, 5%);

.expandable-recommendation-group {
  background: #fff;
  &.screening {
    @include groupGradient($screeningColor);
  }

  &.prevention {
    @include groupGradient($preventionColor);
  }

  &.treatment {
    @include groupGradient($treatmentColor);
  }

  &.diagnosis {
    @include groupGradient($diagnosisColor);
  }

  &.tuberculosisAndComorbidities {
    @include groupGradient($tuberculosisAndComorbiditiesColor);
  }

  &.specialNeedsAndPopulations {
    @include groupGradient($specialNeedsAndPopulationsColor);
  }

  > .ep-dynamic-height-container__inner > .expand-toggle {
    &:after {
      right: 1rem;
    }
  }
}

@mixin tab($color) {
  border-bottom: 4px solid $color;
  &:hover,
  &.tab--active {
    color: #fff;
    background: $color;
  }
  @include breakpoint("sm") {
    border-left: 4px solid $color;
    border-bottom: none;
  }
}

.moduleTab {
  flex-basis: 0;
  line-height: 1.3;
  transition: background-color 0.3s ease;

  &.tab--all {
    @include tab($allColor);
  }

  &.tab--screening {
    @include tab($screeningColor);
  }

  &.tab--prevention {
    @include tab($preventionColor);
  }

  &.tab--treatment {
    @include tab($treatmentColor);
  }

  &.tab--diagnosis {
    @include tab($diagnosisColor);
  }

  &.tab--tuberculosisAndComorbidities {
    @include tab($tuberculosisAndComorbiditiesColor);
  }

  &.tab--specialNeedsAndPopulations {
    @include tab($specialNeedsAndPopulationsColor);
  }
}

.tabs.expanded {
  max-width: calc(100vw - 16px);
}

.recommendations-subgroup {
  .expand-toggle {
    color: #000;
    &:after {
      color: #008dc9;
    }
  }
  &.screening {
    .expand-toggle {
      background: #e49e7b;
    }
    .recommendation-list {
      li {
        background: #fae0d3;
      }
    }
  }

  &.infectionControl,
  &.tbPreventiveTreatment {
    .expand-toggle {
      background: #c3e2fb;
    }
    .recommendation-list {
      li {
        background: #eff5ff;
      }
    }
  }

  &.drugSusceptible,
  &.drugResistant {
    .expand-toggle {
      background: #dadefc;
    }
    .recommendation-list {
      li {
        background: #f8f3ff;
      }
    }
  }

  &.diagnosis {
    .expand-toggle {
      background: #b7dec9;
    }
    .recommendation-list {
      li {
        background: #ebf3ef;
      }
    }
  }

  &.tuberculosisAndComorbidities {
    .expand-toggle {
      background: lighten(rgb(192, 146, 175), 15%);
    }
    .recommendation-list {
      li {
        background: lighten(#ebdbe3, 5%);
      }
    }
  }

  &.specialNeedsAndPopulations {
    .expand-toggle {
      background: lighten($specialNeedsAndPopulationsColor, 15%);
    }
    .recommendation-list {
      li {
        background: lighten($specialNeedsAndPopulationsColor, 25%);
      }
    }
  }
}

.tab-toggle-btn {
  &.expanded {
    &:after {
      transform: rotate(180deg);
    }
  }
  &:after {
    position: absolute;
    right: 0px;
    transition: transform 0.3s ease-in-out;
    font-family: "eml";
    content: "";
    speak: none;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
  }
}

.etb-guidelines-header {
  color: #0072bb;
  border-left: 2px solid #0072bb;
  margin-left: 20px;
  padding: 2px 10px 2px 20px;
}

.export-trigger {
  background: url("../assets/export.svg") no-repeat 0px 2px;
  padding-left: 20px;
}
